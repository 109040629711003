const publicRoutes = [
  {
    path: "",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "public" */ "../public/views/Home.vue"),
  },
  {
    path: "eventi/:id",
    name: "eventDetail",
    component: () =>
      import(
        /* webpackChunkName: "public" */ "../public/views/EventDetail.vue"
      ),
    props: true,
  },
  {
    path: "eventi",
    name: "eventList",
    component: () =>
      import(/* webpackChunkName: "public" */ "../public/views/EventList.vue"),
  },
  {
    path: "iscrizioni",
    name: "subscribe",
    component: () =>
      import(/* webpackChunkName: "public" */ "../public/views/Subscribe.vue"),
  },
  {
    path: "istruzioni",
    name: "instructions",
    component: () =>
      import(
        /* webpackChunkName: "public" */ "../public/views/Instructions.vue"
      ),
  },
  {
    path: "aiuto",
    name: "help",
    component: () =>
      import(/* webpackChunkName: "public" */ "../public/views/Help.vue"),
  },
  {
    path: "bot",
    name: "bot",
    component: () =>
      import(/* webpackChunkName: "public" */ "../public/views/Bot.vue"),
  },
  {
    path: "info",
    name: "info",
    component: () =>
      import(/* webpackChunkName: "public" */ "../public/views/About.vue"),
  },
  {
    path: "privacy",
    name: "privacy",
    component: () =>
      import(/* webpackChunkName: "public" */ "../public/views/Privacy.vue"),
  },
  {
    path: "about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "public" */ "../public/views/About.vue"),
  },
];

export default publicRoutes;
