import Vue from "vue";
import Router from "vue-router";
import AuthSvc from "../services/AuthenticationService.js";
import publicRoutes from "./public.js";
import userRoutes from "./user.js";
import adminRoutes from "./admin.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    // Area routes
    {
      path: "/",
      component: () =>
        import(/* webpackChunkName: "public" */ "../public/LayoutPublic.vue"),
      children: publicRoutes,
    },
    {
      path: "/utente",
      component: () =>
        import(/* webpackChunkName: "user" */ "../user/LayoutUser.vue"),
      children: userRoutes,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin",
      component: () =>
        import(/* webpackChunkName: "admin" */ "../admin/LayoutAdmin.vue"),
      children: adminRoutes,
      meta: {
        requiresAuth: true,
      },
    },
    // Service routes
    {
      path: "/logincallback",
      name: "LoginCallbackView",
      component: () =>
        import(
          /* webpackChunkName: "common" */ "../callbacks/LoginCallback.vue"
        ),
    },
    {
      path: "/renewcallback",
      name: "RenewCallbackView",
      component: () =>
        import(
          /* webpackChunkName: "common" */ "../callbacks/RenewCallback.vue"
        ),
    },
    {
      path: "/logoutcallback",
      name: "LogoutCallbackView",
      component: () =>
        import(
          /* webpackChunkName: "common" */ "../callbacks/LogoutCallback.vue"
        ),
    },
    // Errors
    {
      path: "/errors/accessdenied",
      name: "AccessDenied",
      component: () =>
        import(/* webpackChunkName: "common" */ "../errors/AccessDenied.vue"),
    },
    {
      path: "*",
      name: "PageNotFound",
      component: () =>
        import(/* webpackChunkName: "common" */ "../errors/PageNotFound.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authRequired = to.matched.some((record) => record.meta.requiresAuth); // to.path.startsWith("/admin") || to.path.startsWith("/utente");
  if (!authRequired) {
    next();
  } else {
    const currentUser = await AuthSvc.getUser();
    if (!currentUser) {
      next({ name: "home" });
    } else if (
      to.path.startsWith("/admin") &&
      !currentUser.profile.role.includes("Admin")
    ) {
      next({ name: "AccessDenied" });
    } else {
      next();
    }
  }
});

export default router;
