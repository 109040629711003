import Vue from "vue";
import Vuex from "vuex";
import userModule from "./userModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: userModule,
  },
  state: {
    WReg: "",
    WSY: "",
    Categories: null,
    EulaAccepted: false,
  },
  mutations: {
    setWorkingRegion(state, region) {
      state.WReg = region;
    },
    setWorkingSocialYear(state, year) {
      state.WSY = year;
    },
    setEulaAcceptance(state, eulaAccepted) {
      state.EulaAccepted = eulaAccepted;
    },
    setCategories(state, cats) {
      state.Categories = cats;
    },
  },
  actions: {
    loadSettings(ctx) {
      if (localStorage.WReg) {
        ctx.commit("setWorkingRegion", localStorage.WReg);
      }
    },
    clearWorkingRegion(ctx) {
      ctx.commit("setWorkingRegion", "");
      localStorage.removeItem("WReg");
    },
  },
});
