// Official documentation available at: https://github.com/FortAwesome/vue-fontawesome
// See also https://github.com/FortAwesome/Font-Awesome/issues/12278

import { library } from "@fortawesome/fontawesome-svg-core";
//import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
//import { fal } from "@fortawesome/pro-light-svg-icons";
//import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// If not present, it won't be visible within the application. Considering that you
// don't want all the icons for no reason. This is a good way to avoid importing too many
// unnecessary things.
library.add(far, fab);

// require('@fortawesome/pro-solid-svg-icons/faAbacus').definition

export { FontAwesomeIcon };
