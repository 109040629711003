import Oidc from "oidc-client";

class AuthenticationService extends Oidc.UserManager {
  constructor() {
    super({
      //userStore: new require("./VuexStorageStateStore.js").default,
      //userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
      authority: process.env.VUE_APP_AUTHORITY,
      redirect_uri: process.env.VUE_APP_LOGIN_URI,
      client_id: "bcVue",
      response_type: "code",
      response_mode: "query",
      scope: "openid profile email phone roles bcApi",
      post_logout_redirect_uri: process.env.VUE_APP_LOGOUT_URI,
      // automaticSilentRenew: true,
      // silent_redirect_uri: process.env.VUE_APP_RENEW_URI
    });
  }

  async login() {
    return this.signinRedirect();
  }

  async logout() {
    return this.signoutRedirect();
  }

  async getuser() {
    return this.getUser();
  }

  async isuserinrole(role) {
    const user = await this.getUser();
    return user.profile.role.Contains(role);
  }
}

//Oidc.Log.logger = console;
//Oidc.Log.level = Oidc.Log.INFO;

const authenticationService = new AuthenticationService();

export { authenticationService as default };
