const adminRoutes = [
  {
    path: "",
    name: "userHome",
    component: () =>
      import(/* webpackChunkName: "user" */ "../user/views/Home.vue"),
  },
];

export default adminRoutes;
