export default {
  namespaced: true,

  state: {
    name: "",
    isAuthenticated: false,
    roles: [],
    currentJWT: "",
    oidcState: [],
  },

  getters: {
    jwt: (state) => state.currentJWT,
    jwtData: (state, getters) =>
      state.currentJWT ? JSON.parse(atob(getters.jwt.split(".")[1])) : null,
    jwtSubject: (state, getters) =>
      getters.jwtData ? getters.jwtData.sub : null,
    jwtIssuer: (state, getters) =>
      getters.jwtData ? getters.jwtData.iss : null,
  },

  mutations: {
    setJWT(state, jwt) {
      state.currentJWT = jwt;
    },
    setAuthState(state, user) {
      state.user.name = user.profile.name;
      state.user.roles.push(user.profile.role);
      state.user.isAuthenticated = true;
    },
    clearAuthState(state) {
      state.name = "";
      state.roles.splice(0);
      state.isAuthenticated = false;
    },
    setOidcState(state, props) {
      state.oidcState = props;
    },
  },

  actions: {
    // async fetchJWT({ commit }, { username, password }) {
    //   // Perform the HTTP request.
    //   const res = await fetch(
    //     `http://localhost/vuejs-jwt-example/auth?u=${username}&p=${password}`
    //   );
    //   // Calls the mutation defined to update the state's JWT.
    //   commit("setJWT", await res.text());
    // }
  },
};
