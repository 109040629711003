import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// Application Insights
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
if (process.env.NODE_ENV != "development") {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: "c063b815-a9c3-413b-8ffd-132b01290dd3",
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
}

// Axios
const axios = require("axios");
axios.defaults.baseURL = process.env.VUE_APP_APIBASEURL;
axios.defaults.headers.common["Authorization"] =
  "BC " + process.env.VUE_APP_TOKEN;
Vue.prototype.$http = axios;

// FontAwesome Icons
import { FontAwesomeIcon } from "./shared/icons";
// eslint-disable-next-line
Vue.component("fa-icon", FontAwesomeIcon);

// PortalVue
import PortalVue from "portal-vue";
Vue.use(PortalVue);

// Bootstrap
import "@/assets/bootstrap/bootstrap.scss";
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue, {
  BToast: { toaster: "b-toaster-bottom-right" },
});

// Moment
import moment from "moment";
moment.locale("it");
Vue.prototype.moment = moment;

// ENTRYPOINT
Vue.config.productionTip = false;

if (process.env.NODE_ENV != "production") {
  if (process.env.NODE_ENV == "development")
    document.title = document.title + " [DEV]";
  if (process.env.NODE_ENV == "staging")
    document.title = document.title + " [STAGE]";
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
