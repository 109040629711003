const adminRoutes = [
  {
    path: "",
    name: "adminHome",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/Dashboard.vue"),
  },

  {
    path: "eventi/consolidamenti",
    name: "adminConsolidations",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../admin/views/EventConsolidations.vue"
      ),
  },
  {
    path: "eventi/:eid",
    name: "adminEventDetail",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/EventDetail.vue"),
    props: true,
  },
  {
    path: "eventi",
    name: "adminEventList",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/EventList.vue"),
  },
  {
    path: "reports",
    name: "adminReports",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/EventReports.vue"),
  },
  {
    path: "comunicati",
    name: "adminComms",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/EventComms.vue"),
  },

  {
    path: "iscrizioni/checkin",
    name: "adminSubCheckin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/SubCheckin.vue"),
  },
  {
    path: "iscrizioni/sospesi",
    name: "adminSubOnHold",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/SubOnHold.vue"),
  },
  {
    path: "iscrizioni/:sid",
    name: "adminSubDetail",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/SubDetail.vue"),
    props: true,
  },
  {
    path: "iscrizioni",
    name: "adminSubNew",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/SubNew.vue"),
  },
  {
    path: "documenti",
    name: "adminDocDigitalList",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../admin/views/DocDigitalList.vue"
      ),
  },
  {
    path: "analogici",
    name: "adminDocAnalogList",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../admin/views/DocDigitalList.vue"
      ),
  },

  {
    path: "alias",
    name: "adminAliasList",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/AliasList.vue"),
  },
  {
    path: "modelli",
    name: "adminTemplateList",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/TemplateList.vue"),
  },
  {
    path: "datistatici",
    name: "adminStaticData",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/StaticData.vue"),
  },
  {
    path: "configurazione",
    name: "adminConfiguration",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../admin/views/Configuration.vue"
      ),
  },
  {
    path: "manutenzione",
    name: "adminMaintenance",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/Maintenance.vue"),
  },

  // TODO: Oversight routes

  {
    path: "persone/:pid",
    name: "adminPersonDetail",
    component: () =>
      import(/* webpackChunkName: "admin" */ "../admin/views/PersonDetail.vue"),
    props: true,
  },
];

export default adminRoutes;
